<template>
  <template v-for="(item, index) in logList" v-bind:key="index">
    <table class="ticket-log" v-if="showAll || index <= 0">
      <thead>
        <tr>
          <th>{{ $t('work_order_type') }} [{{ item.ticket_type_name_cn }}]</th>
          <th>{{ $t('work_order_status') }} <span :class="'t_status' + item.ticket_status">{{ ticketStatusArr[item.ticket_status] }}</span></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="3">
            <a-steps :current="0" size="small" direction="vertical">
              <template v-for="(log, no) in item.TicketLogList" v-bind:key="no">
                <a-step>
                  <template #icon>
                    <span :class="'step-dot t_status' + log.ticket_status"></span>
                  </template>
                  <template #title>
                    <span class="title-font">{{ dayjs.unix(log.ticket_handle_time).format('YYYY-MM-DD HH:mm') }}</span>
                  </template>
                  <template #subTitle>
                    <span class="remark-font">{{ log.ticket_handler_name }} (客服)</span>
                  </template>
                  <template #description>
                    <div class="title-font">
                      {{ log.ticket_reply_content }}
                    </div>
                    <div v-if="!isEmpty(log.ticket_reply_appendix)" class="div_appendix">
                      <ul>
                        <li v-for="(url, j) in log.ticket_reply_appendix" v-bind:key="j">
                          <a :href="url" target="_blank">
                            <img v-if="isImageUrl(url)" :src="url" />
                            <file-outlined v-else style="font-size: 25px;margin: auto;" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </template>
                </a-step>
              </template>
              <a-step>
                <template #icon>
                  <span class="step-dot t_status0"></span>
                </template>
                <template #title>
                  <span class="title-font">{{ dayjs.unix(item.ticket_sender_time).format('YYYY-MM-DD HH:mm') }}</span>
                </template>
                <template #subTitle>
                  <span class="remark-font">我</span>
                </template>
                <template #description>
                  <div class="title-font">
                  {{ item.ticket_content }}
                  </div>
                  <div class="remark-font" v-if="!isEmpty(item.ticket_remark)">
                    <strong>{{ $t('remark') }}</strong>: <br />{{ item.ticket_remark }}
                  </div>
                  <div v-if="!isEmpty(item.ticket_appendix)" class="div_appendix">
                    <ul>
                      <li v-for="(url, index) in item.ticket_appendix" v-bind:key="index">
                        <a :href="url" target="_blank">
                          <img v-if="isImageUrl(url)" :src="url" />
                          <file-outlined v-else style="font-size: 25px;margin: auto;" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </template>
              </a-step>
            </a-steps>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
  <a-divider v-if="logList.length > 1">
    <a @click="showAll = !showAll">
      {{ showAll ? $t('hide') : $t('more') }}
      <template v-if="showAll">
        <UpOutlined/>
      </template>
      <template v-else>
        <DownOutlined/>
      </template>
    </a>
  </a-divider>
</template>

<script>
import dayjs from "dayjs";
import {
  UpOutlined,
  DownOutlined,
  FileOutlined
} from '@ant-design/icons-vue';
import {getTicketListByOrderId} from "@/services/ticket";
import {isEmpty, isImageUrl} from "@/utils/util";

export default {
  name: "TicketLog",
  components: {
    UpOutlined,
    DownOutlined,
    FileOutlined
  },
  props: {
    order_id: String,
    ticket_id: Number,
  },
  data(){
    let ticketStatusArr = this.$tm('ticket_status')

    return {
      dayjs,isEmpty,isImageUrl,
      ticketStatusArr,
      loading: false,
      logList: [],
      showAll: false,
      statusClassName: '',
    }
  },
  watch:{
    order_id: function (value, value1) {
      if(value === value1)
        return

      this.getLogList()
    }
  },
  mounted() {
    this.getLogList()
  },
  methods:{
    getLogList(){
      this.loading = true
      getTicketListByOrderId(this.order_id, this.ticket_id).then(res => {
        this.loading = false
        this.showAll = false
        const resData = res.data
        if (resData.Success) {
          this.logList = resData.Data
          this.logList.forEach(item => {
            item.ticket_appendix = eval(item.ticket_appendix)
            item.TicketLogList.forEach(re => {
              re.ticket_reply_appendix = eval(re.ticket_reply_appendix)
            })
          })
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.ticket-log {
  width: 100%;
  border: rgba(0, 0, 0, 0.06) solid 1px;
  margin-bottom: 10px;
}
.ticket-log th {
  width: 40%;
  text-align: left;
  background-color: #FAFAFA;
  font-size: 14px;
  padding: 10px;
}
.ticket-log tbody td {
  padding: 5px 10px;
}
.ticket-log .title-font {
  font-size: 14px;color: #333333;
}
.ticket-log .remark-font {
  font-size: 13px;color: #333333;
}

.div_appendix ul{
  margin: 10px 0 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  font-size: 12px;
}
.div_appendix li{
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.div_appendix li a{
  display: flex;
  width:100%;
  height: 100%;
  overflow: hidden;
}
.div_appendix li a img{
  max-height: 50px;
  margin: auto;
}

.step-dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius:10px;
  margin: 10px 7px;
}
</style>