import {
    TICKET_TYPES,
    TICKET_UPLOAD,
    TICKET_CREATE,
    TICKET_GETLISTBYORDERID,
    TICKET_GETLIST
} from '@/services/api'

import {request, METHOD} from '@/utils/request'

export async function getTypes() {
    return request(TICKET_TYPES, METHOD.GET)
}

export async function updateFile(data){
    return request(TICKET_UPLOAD, METHOD.POST, data)
}

export async function createTicket(params){
    return request(TICKET_CREATE, METHOD.POST, params)
}

export async function getTicketListByOrderId(orderId, ticketId) {
    return request(TICKET_GETLISTBYORDERID, METHOD.GET, {orderId: orderId, ticketId})
}

export async function getTicketList(params){
    return request(TICKET_GETLIST, METHOD.POST, params)
}
