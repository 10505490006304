<template>
  <span :class="className"><SyncOutlined :spin="true" v-if="orderStatus === OrderHotelStatus.NewOrder || orderStatus === OrderHotelStatus.Pending" /> {{ $t('order_hotel_status.' + orderStatus) }}</span>
</template>

<script>
import {OrderHotelStatus} from "@/data/order-hotel-status";
import {SyncOutlined} from '@ant-design/icons-vue';

export default {
  name: "OrderHotelStatusTag",
  components: { SyncOutlined },
  props: {
    orderStatus: Number,
  },
  data(){
    let className = 'ohs'

    switch (this.orderStatus){
      case OrderHotelStatus.Success:
        className = 'ohs-success'
        break;
      case OrderHotelStatus.Pending:
      case OrderHotelStatus.NewOrder:
        className = 'ohs-pending'
        break;
      case OrderHotelStatus.Unpaid:
        className = 'ohs-unpaid'
        break;
      case OrderHotelStatus.Canceled:
        className = 'ohs-canceled'
        break;
      case OrderHotelStatus.Fail:
        className = 'ohs-fail'
        break;
    }

    return {
      className,
      OrderHotelStatus
    }
  },
}
</script>

<style scoped>
.ohs, .ohs-success, .ohs-pending, .ohs-unpaid, .ohs-canceled, .ohs-fail{
  color:#FFFFFF;
  font-size: 13px;
  padding: 0 5px;
  display: inline-block;
  border-radius:2px;
}
.ohs-success {
  background-color: #52c41a;
}
.ohs-pending {
  background-color: #1890ff;
}
.ohs-unpaid {
  background-color: #FF5C00;
}
.ohs-canceled {
  background-color: rgba(0, 0, 0, 0.5);
}
.ohs-fail {
  background-color: #f5222d;
}
</style>